.e-grid {
  font-family: "Inter", sans-serif !important;
  // border: 0px;
  border-color: #D2DBEE;
  .e-gridheader {
    border-top: 0px;
  }
}

.e-grid .e-frozenheader > .e-table,
.e-grid .e-frozencontent > .e-table,
.e-grid .e-frozencontent .e-virtualtable > .e-table,
.e-grid .e-frozenheader .e-virtualtable > .e-table {
  border: 0px !important;
}

.e-grid .e-rowcell {
  &.customcss {
    // font-size: 14px;
    // font-weight: 600;
    // font-weight: 500;
    // font-size: 16px;
    // font-weight: 600;
    background-color: #FBFBFD;
    // color: #4766EC !important;
    padding-right: 22px;
  }
}

.e-grid .child-row .e-rowcell {
  &.customcss {
    background-color: #fff;
  }
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  border-color: #D2DBEE;
}

.e-grid .e-rowcell,
.e-grid .e-groupcaption,
.e-grid .e-indentcell,
.e-grid .e-recordplusexpand,
.e-grid .e-recordpluscollapse,
.e-grid .e-rowdragdropcell,
.e-grid .e-detailrowcollapse,
.e-grid .e-detailrowexpand,
.e-grid .e-detailindentcell,
.e-grid .e-detailcell {
  border-color: #D2DBEE;
}

.e-grid .e-headercelldiv {
  padding-right: 8px;
}

.e-rowcell.customcss {
  padding-top: 0px;
  padding-bottom: 0px;
}

.child-row {
  background-color: #fff;

  .e-rowcell {
    // font-size: 14px;
    font-size: 12px;
    font-weight: 400;
    background-color: #fff;
    color: #1c1f2c;
    &.customcss {
      color: #1c1f2c !important;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 12px;
      .e-treecolumn-container {
        // height: 45px;
        // line-height: 45px;
        margin-left: 35px;
        padding-left: 0px;
        .e-none {
          display: none !important;
        }
      }
    }
  }
}

.e-grid.e-default .e-rowcell.e-lastrowcell {
  border-bottom-width: 0px;
}

.pl-div,
.div-table-pl,
.expense-report-div {
  .e-toolbar.e-control {
    text-align: right;
  }
  // .e-treecolumn-container {
  //   padding-left: 8px;
  //   border-left: 4px solid transparent;
  // }
  .e-frozen-left-content .e-rowcell{
    border-color: #D2DBEE;
    min-width: 100px;
    &:first-child {
      padding-left: 12px;
      // border-right: 1px solid #BAC4D9;
      border-right: 0px solid #BAC4D9;
      width: 270px;

    }
  }
  // .e-rowcell {
  //   border-color: #D2DBEE;

  //   min-width: 100px;
    // &:first-child {
    //   padding-left: 12px;
    //   border-right: 1px solid #BAC4D9;
    //   width: 270px;

    // }
  // }

  // .e-treecolumn-container:has(> span.e-treegridcollapse) {
  //   padding-left: 5px;
  // }
  // .e-treecolumn-container:has(> span.e-treegridexpand) {
  //   border-left:4px solid #1F8523;
  //   padding-left: 5px;
  // }
  .e-row:last-child td {
    // background-color: #f6f9fc;
    background-color: #FBFBFD;
    border-bottom: 0px solid #D2DBEE;
    font-weight: 600;

  }
}

.e-treegrid {
  .e-headercell.customcss {
    // background-color: #f6f9fc;
    // background-color: #F3F5F7;
    // background-color: #e8f3ff;
    background-color: #ffffff;
    // color: #1c1f2c;
    color: #1c1f2c;
    border-top: 1px solid #D2DBEE !important;
    border-color: #D2DBEE;
    // height: 56px;
    font-size: 14px;
    font-weight: 600;
    &:first-child {
      // border-right: 1px solid #d6d6d6;
      width: 270px;
      background-color: #fff;
      // .e-headercelldiv {
      //   border: 0px;
      // }
    }
    &:last-child {
      .e-headercelldiv {
        border: 0px;
      }
    }
    .e-headercelldiv {
      // height: 56px;
      font-size: 14px;
      font-weight: 500;
      // border-right: 1px solid #D2DBEE;
      // padding-right: 20px;
      // text-align: center !important;
      .e-headertext {
        text-transform: uppercase;
        color:#16266B;
        font-weight: 600;
      }
    }
  }
  .e-treecolumn-container {
    // height: 30px;
    // line-height: 30px;
    // height: 45px;
    // line-height: 45px;
    color: #1c1f2c;
    text-transform: capitalize;
  }
}

.e-rowcell {
  height: 45px;
  line-height: 45px;
}



.div-table-pl {
  .e-treegrid {
    .e-movablecontent .e-rowcell.customcss:first-child {
      border-right: 0px;
      // font-size: 12px;
    }
    .e-movableheader {
      .e-headercell.customcss {
        &.e-movablefirst {
          // width: 270px;
          background-color: #ffffff;
          // .e-headercelldiv {
          //   border: 0px;
          // }
        }
      }
    }
  }
}

// .e-grid .e-frozencontent .e-table .e-rowcell.customcss {
//   background-color: #fff;
//   .e-treecolumn-container {
//     color: #16266B;
//     // font-weight: 600;
//     font-weight: 500;
//   }
// }
// .e-grid .e-frozencontent .e-table .child-row .e-rowcell.customcss {
//   background-color: #fff;
//   .e-treecolumn-container {
//     color: #1c1f2c;
//     font-weight: 500;
//   }
// }


.e-grid .e-frozenheader .e-table tr.e-columnheader th.e-headercell {
  // border-right: 1px solid #BAC4D9;
}
.expense-report-div {
  .e-grid .e-gridheader tr th:first-child {
    padding-left: 42px;
    // border-right: 1px solid #BAC4D9;
    .e-headercelldiv {
      border-right:0px;
    }
  }
}

.bs-div {
  .e-columnheader{
    display: none;
  }
  .e-grid {
    width: 45% !important;
    margin: auto;
    // padding-top: 50px;
    .e-content {
      overflow: auto!important;
    }
  }


}

#chart-vertical_tooltip_text {
  font-family: "Inter", sans-serif !important;
  font-size: 12px !important;
}

.e-grid .e-gridcontent .e-content {
  .e-table {
    tr.class-level-0 {
      td {
        color: #4766EC;
        font-size: 16px;
        font-weight: 600;
        &:first-child {
          .e-treecolumn-container {
            font-size: 16px;
            font-weight: 600;
            color: #16266B;
          }
        }
      }
    }
    tr.class-level-1, tr.class-level-2, tr.class-level-3  {
      td {
        color: #1C1F2C;
        font-size: 14px;
        font-weight: 500;
      }
    }
    tr.child-row {
      td.e-rowcell {
        background-color: #fff;
        font-size: 14px;
        font-weight: 400;
        color:#1C1F2C;
        .e-treecolumn-container {
          font-size: 14px;
          font-weight: 400;
          color:#1C1F2C;
        }
      }
    }
  }
  .e-frozencontent {
    .e-table {
      tr.e-row {
        td.e-rowcell {
          background-color: #fff;
          .e-treecolumn-container {
            font-size: 16px;
            font-weight: 600;
            color: #16266B;
          }
        }
        &.class-level-1, &.class-level-2, &.class-level-3 {
          td.e-rowcell {
            background-color: #fff;
            .e-treecolumn-container {
              color: #1C1F2C;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        &.child-row {
          td.e-rowcell {
            background-color: #fff;
            .e-treecolumn-container {
              font-size: 14px;
              font-weight: 400;
              color:#1C1F2C;
            }
          }
        }
      }
    }
  }
  .e-movablecontent {
    .e-table {
      tr.e-row {
        td.e-rowcell {
          color: #4766EC;
          font-size: 16px;
          font-weight: 600;
        }
        &.class-level-1, &.class-level-2, &.class-level-3 {
          td.e-rowcell {
            color: #1C1F2C;
            font-size: 14px;
            font-weight: 500;
          }
        }
        &.child-row {
          td.e-rowcell {
            font-size: 14px;
            font-weight: 400;
            color:#1C1F2C;
          }
        }
      }
    }
  }
}

.mdc-notched-outline__trailing,
.mdc-notched-outline__notch,
.mdc-notched-outline__leading {
    border: none !important;
}


.mat-mdc-paginator-container , .mat-mdc-paginator .mat-mdc-select-value{
  color: #1C1F2C;
  font-size: 14px;
  font-weight: 500;
}

.e-grid.e-resize-lines .e-headercell .e-rhandler {
  border-right-width: 0px;
}
.e-popup .e-calendar .e-start-date.e-selected.e-end-date.e-range-hover span.e-day {
  background-color: #4290F2 !important; 
  color: #FFFFFF !important; 
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover{
  background: rgba(66, 144, 242, 0.10);
  color: rgba(0,0,0,.87);
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover span{
  background: rgba(66, 144, 242, 0.10);
  color: rgba(0,0,0,.87);
  border-radius:23px;
}

.e-btn.e-flat.e-primary:disabled, .e-css.e-btn.e-flat.e-primary:disabled {
  color: rgba(0,0,0,.26) !important;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  color: #4290F2 !important;
}

 td.e-selected span.e-day{
  background-color: #4290F2 !important;
  border: none;
  border-radius: 50%;
  color: #fff !important;
}
