// Custom Toast Message - Starts

#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: relative;
  font-size: 24px;
  line-height: 18px;
  float: left;
  margin-left: -1em;
  color: #FFF;
  padding-right: 0.5em;
  margin-right: 0.5em;

}
#toast-container .toast{
  background-color: #1b75bc !important;
}
#toast-container> .fa-check-circle , .toast {
  background-color: #C7E3CC !important;
}
#toast-container> .fa-exclamation-triangle , .toast  {
  background-color: #FEE2D5 !important;
}
#toast-container> .fa-check-circle {
  color: #328936;
}
#toast-container> .fa-exclamation-triangle {
  color: #EB2E2E;
}
#toast-container .toast-message{
  color: #4d4d4d;
}
#toast-container .fa-check-circle:before, #toast-container .fa-exclamation-triangle:before {
  position: absolute;
  left: 15px;
  font-size: 18px;
}

.toast-top-center {
  text-align: center;
  top:20px !important;
}

#toast-container .toast-title {
  display:none;
}

.toast-container .ngx-toastr {
  text-align: left;
  width: 375px !important;
  padding: 15px 15px 11px 45px !important;
  box-shadow: none !important;
}

#toast-container .toast-close-button {
  color:#4d4d4d;
  font-weight: 200;
  text-shadow: 0 1px 0 #4d4d4d;
  top: -3px;
}

// Custom Toast Message - Ends

//   Page loader Start

div#load_screen {
    /* background: #ECEFFF; */
    /* background-color: #191C24;
    opacity: 1; */
    background-color: #000;
    opacity: 0.9;
    position: fixed;
    z-index: 999999;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; }
    div#load_screen .loader {
      display: flex;
      justify-content: center;
      height: 100vh; }
    div#load_screen .loader-content {
      right: 0;
      align-self: center; }
  .loader_text {
    color: #f6a723;
    margin-top: 20px;
    p {
      text-align: center;
    }
  }




  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #f6a723;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 4px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 4px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 28px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }

  
  .loader_steps {
    position: relative;
    width: 75px;
    height: 100px;
  }
  .loader__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    /* background: #fff; */
    background: #fff;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  }
  .loader__bar:nth-child(1) {
    left: 0px;
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
    -webkit-animation: barUp1 4s infinite;
            animation: barUp1 4s infinite;
  }
  .loader__bar:nth-child(2) {
    left: 15px;
    background: #fff;
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
    -webkit-animation: barUp2 4s infinite;
            animation: barUp2 4s infinite;
  }
  .loader__bar:nth-child(3) {
    left: 30px;
    background: #fff;
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
    -webkit-animation: barUp3 4s infinite;
            animation: barUp3 4s infinite;
  }
  .loader__bar:nth-child(4) {
    left: 45px;
    background: #fff;
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
    -webkit-animation: barUp4 4s infinite;
            animation: barUp4 4s infinite;
  }
  .loader__bar:nth-child(5) {
    left: 60px;
    background: #fff;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    -webkit-animation: barUp5 4s infinite;
            animation: barUp5 4s infinite;
  }
  .loader__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    /* background: #fff; */
    background: #f6a723;
    border-radius: 50%;
    -webkit-animation: ball 4s infinite;
            animation: ball 4s infinite;
  }
  @-webkit-keyframes ball {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    5% {
      -webkit-transform: translate(8px, -14px);
              transform: translate(8px, -14px);
    }
    10% {
      -webkit-transform: translate(15px, -10px);
              transform: translate(15px, -10px);
    }
    17% {
      -webkit-transform: translate(23px, -24px);
              transform: translate(23px, -24px);
    }
    20% {
      -webkit-transform: translate(30px, -20px);
              transform: translate(30px, -20px);
    }
    27% {
      -webkit-transform: translate(38px, -34px);
              transform: translate(38px, -34px);
    }
    30% {
      -webkit-transform: translate(45px, -30px);
              transform: translate(45px, -30px);
    }
    37% {
      -webkit-transform: translate(53px, -44px);
              transform: translate(53px, -44px);
    }
    40% {
      -webkit-transform: translate(60px, -40px);
              transform: translate(60px, -40px);
    }
    50% {
      -webkit-transform: translate(60px, 0);
              transform: translate(60px, 0);
    }
    57% {
      -webkit-transform: translate(53px, -14px);
              transform: translate(53px, -14px);
    }
    60% {
      -webkit-transform: translate(45px, -10px);
              transform: translate(45px, -10px);
    }
    67% {
      -webkit-transform: translate(37px, -24px);
              transform: translate(37px, -24px);
    }
    70% {
      -webkit-transform: translate(30px, -20px);
              transform: translate(30px, -20px);
    }
    77% {
      -webkit-transform: translate(22px, -34px);
              transform: translate(22px, -34px);
    }
    80% {
      -webkit-transform: translate(15px, -30px);
              transform: translate(15px, -30px);
    }
    87% {
      -webkit-transform: translate(7px, -44px);
              transform: translate(7px, -44px);
    }
    90% {
      -webkit-transform: translate(0, -40px);
              transform: translate(0, -40px);
    }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }
  @keyframes ball {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    5% {
      -webkit-transform: translate(8px, -14px);
              transform: translate(8px, -14px);
    }
    10% {
      -webkit-transform: translate(15px, -10px);
              transform: translate(15px, -10px);
    }
    17% {
      -webkit-transform: translate(23px, -24px);
              transform: translate(23px, -24px);
    }
    20% {
      -webkit-transform: translate(30px, -20px);
              transform: translate(30px, -20px);
    }
    27% {
      -webkit-transform: translate(38px, -34px);
              transform: translate(38px, -34px);
    }
    30% {
      -webkit-transform: translate(45px, -30px);
              transform: translate(45px, -30px);
    }
    37% {
      -webkit-transform: translate(53px, -44px);
              transform: translate(53px, -44px);
    }
    40% {
      -webkit-transform: translate(60px, -40px);
              transform: translate(60px, -40px);
    }
    50% {
      -webkit-transform: translate(60px, 0);
              transform: translate(60px, 0);
    }
    57% {
      -webkit-transform: translate(53px, -14px);
              transform: translate(53px, -14px);
    }
    60% {
      -webkit-transform: translate(45px, -10px);
              transform: translate(45px, -10px);
    }
    67% {
      -webkit-transform: translate(37px, -24px);
              transform: translate(37px, -24px);
    }
    70% {
      -webkit-transform: translate(30px, -20px);
              transform: translate(30px, -20px);
    }
    77% {
      -webkit-transform: translate(22px, -34px);
              transform: translate(22px, -34px);
    }
    80% {
      -webkit-transform: translate(15px, -30px);
              transform: translate(15px, -30px);
    }
    87% {
      -webkit-transform: translate(7px, -44px);
              transform: translate(7px, -44px);
    }
    90% {
      -webkit-transform: translate(0, -40px);
              transform: translate(0, -40px);
    }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
  }
  @-webkit-keyframes barUp1 {
    0% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    40% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    50% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    90% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    100% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
  }
  @keyframes barUp1 {
    0% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    40% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    50% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    90% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    100% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
  }
  @-webkit-keyframes barUp2 {
    0% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    40% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    50% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    90% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    100% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
  }
  @keyframes barUp2 {
    0% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    40% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    50% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    90% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    100% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
  }
  @-webkit-keyframes barUp3 {
    0% {
      -webkit-transform: scale(1, 0.6);
              transform: scale(1, 0.6);
    }
    100% {
      -webkit-transform: scale(1, 0.6);
              transform: scale(1, 0.6);
    }
  }
  @keyframes barUp3 {
    0% {
      -webkit-transform: scale(1, 0.6);
              transform: scale(1, 0.6);
    }
    100% {
      -webkit-transform: scale(1, 0.6);
              transform: scale(1, 0.6);
    }
  }
  @-webkit-keyframes barUp4 {
    0% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    40% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    50% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    90% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    100% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
  }
  @keyframes barUp4 {
    0% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    40% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
    50% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    90% {
      -webkit-transform: scale(1, 0.4);
              transform: scale(1, 0.4);
    }
    100% {
      -webkit-transform: scale(1, 0.8);
              transform: scale(1, 0.8);
    }
  }
  @-webkit-keyframes barUp5 {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    40% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    50% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    90% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  @keyframes barUp5 {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    40% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    50% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    90% {
      -webkit-transform: scale(1, 0.2);
              transform: scale(1, 0.2);
    }
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

//   Page loader End