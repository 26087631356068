@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: url('../font/inter/Inter-Thin.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url('../font/inter/Inter-ExtraLight.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('../font/inter/Inter-Light.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src:
      /* Modern Browsers */
    url('../font/inter/Inter-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../font/inter/Inter-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../font/inter/Inter-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../font/inter/Inter-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('../font/inter/Inter-ExtraBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('../font/inter/Inter-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NanumGothicCoding';
    font-style: normal;
    font-weight: 400;
    src: url('../font/Nanum_Gothic_Coding/NanumGothicCoding-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'NanumGothicCoding';
    font-style: bold;
    font-weight: 700;
    src: url('../font/Nanum_Gothic_Coding/NanumGothicCoding-Bold.ttf') format('truetype');
  }