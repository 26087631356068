// Tooltip start

.numero-tooltip {
  .tooltip-arrow::before {
    border-bottom-color: #fdfdfd;
  }
  .tooltip-inner {
    background-color: #fdfdfd;
    color: #1c1f2c;
    text-align: left;
  }
}

.workflow-tooltip {
  border: 1px solid #dddddf;
  &.show {
    left: -145px !important;
    top: 8px !important;
    padding: 0px !important;
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 380px;
    min-width: 380px;
    min-height: 200px;
    background-color: #fdfdfd;
    padding: 16px;
    div {
      width: 100%;
    }
  }
}
.contract-filter-tooltip {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 12px 0px #ebebeb;
  &.show {
    left: 160px !important;
    // top: 8px!important;
    padding: 0px !important;
    opacity: 1;
    z-index: 1;
  }
  &:not(.show) {
    display: none;
  }
  .tooltip-inner {
    max-width: 680px;
    min-width: 680px;
    min-height: 250px;
    background-color: #fdfdfd;
    padding: 16px;
    div {
      // width: 100%;
    }
    .fd {
      &-header {
        height: 50px;
        align-content: center;
        .title {
          color: #1c1f2c;
          font-size: 16px;
          font-weight: 600;
        }
        .sub {
          text-align: end;
          color: #8d8f95;
          font-size: 14px;
          font-weight: 500;
        }
      }
      &-grid {
        align-items: center;
        .condition {
          color: #1c1f2c;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

.dunning-te {
  &.invalid-border {
    &.e-richtexteditor.e-rte-tb-expand {
      border: 1px solid red !important ;
    }
  }
}

.e-multiselect.e-input-group.e-control-wrapper.e-input-focus::before,
.e-multiselect.e-input-group.e-control-wrapper.e-input-focus::after {
  background: #0d6efd;
}

.accordion-body {
  height: 170px;
  overflow: auto;
}

// -- Start Common class for accordion ---- //
.nu-accordion {
//   .accordion-button:not(.collapsed)::after {
//     background-image: url('../image/temp/arrow-down.svg') !important;
//     transform: rotate(-180deg) !important;
//     background-size: 12px 12px !important;
//   }

//  .accordion-button:after {
//     background-image: url('../image/temp/arrow-up.svg') !important;
//     transform: rotate(-180deg) !important;
//     background-size: 12px 12px !important;
//   }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    color: #16266b !important;
    background-color: #ffffff !important;
    box-shadow: none;
    border-radius: 12px;
  }
}

// -- End Common class for accordion ---- //


.contract-preview-accordion {
  .accordion-item{
    border: 1px solid #D2DBEE;
    border-radius: 12px !important;
    margin-bottom: 12px;
  }
  .accordion-body {
    height: auto !important;
    // overflow: hidden !important;
    border-radius: 2px !important;
    // border: 1px solid #D2DBEE;
    padding: 0px 0px 0px 20px;
    overflow-x: hidden;
  }
  .accordion-button {
    color: #16266b;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px !important;
  }
  .accordion-button::after {
    margin-left: 2px;
    font-weight: bold;
    color: #16266b;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  // .accordion-button:focus {
  //   box-shadow: none;
  // }
  // .accordion-button:not(.collapsed) {
  //   color: #16266b !important;
  //   background-color: white !important;
  //   box-shadow: none;
  //   border-radius: 12px;
  // }
  .accordion-button:not(.collapsed)::after {
    background-image: url('../image/temp/Down.svg') !important;
    transform: rotate(-180deg) !important;
    background-size: 12px 12px !important;
  }

 .accordion-button:after {
    background-image: url('../image/temp/up.svg') !important;
    transform: rotate(-180deg) !important;
    background-size: 12px 12px !important;
  }
}


// .copilot-accordion {
//   .accordion-item{
//     border: 0;
//     border-radius: 12px !important;
//   }
//   .accordion-body {
//     height: auto !important;
//     // overflow: hidden !important;
//     // border-radius: 12px !important;
//     padding: 0 0 0 0;
//     // border: 1px solid #D2DBEE;
//     // padding: 0px 20px 0px;
//   }
//   .accordion-button {
//     color: rgba(28, 31, 44, 0.5);
//     font-size: 16px;
//     font-weight: 600;
//     border-radius: 12px !important;
//     align-items: flex-end;
//     background-color: #FAFAFA;
//     padding-left: 0;
//     padding-right: 0;

//   }

//     .accordion-button:not(.collapsed)::after {
//     // background-image: url('../image/temp/arrow-down.svg') !important;
//     content: '\f054';
//     background-image: none!important;
//     font: var(--fa-font-regular);
//     transform: rotate(90deg) !important;
//     background-size: 12px 12px !important;

//   }

//  .accordion-button:after {
//     // background-image: url('../image/temp/arrow-up.svg') !important;
//     content: '\f054';
//     font: var(--fa-font-regular);
//     background-image: none!important;

//     // transform: rotate(90deg) !important;
//     background-size: 12px 12px !important;
//   }
//   // .accordion-button::after {
//   //   margin-left: 2px;
//   //   font-weight: bold;
//   //   color: #16266b;
//   //   font-size: 16px;
//   //   display: flex;
//   //   align-items: center;
//   //   margin-bottom: 2px;
//   // }

// }


//************ modal start *********//

.knock-off {
  &-modal , &-backdrop {
    z-index: 101!important;
  }

  &-modal {
    .accordion {
    &-button {
      background: #1C1F2C0A;
      color: #494C56;
    }
    &-close {
      position: absolute;
      right: -30px;
      top: 18px;
    }
    &-submit {
      background: #1F8523;
      border-radius: 4px;
      color: #ffffff;
      &.disabled-btn {
      background: #DBDCDE;
      pointer-events: none;
      color: #1C1F2C;
      }
    }
    &-btn-head {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
}

.contract-folder-selection {
  &-modal , &-backdrop {
    z-index: 101!important;
  }

  &-modal {
    .modal{
    &-body {
      .btn-div {
        margin-top: 26px;
        text-align: center;
      }
    }
  }
}
}
//*********** modal end*************//




// ---------- Table start---------------- //
.mat-mdc {
  &-table {

  }
  &-row {
    &:hover {
      background-color: #F0F4F9!important;
    }
  }
}

// ---------- Table end ---------------- //


// ----------Numero Tab Start ---------------- //
.div-numero-tabs {
  // border-bottom: 2px solid #e8e9ef;
  width: 100%;
  // margin-top: 30px;
  // margin-bottom: 24px;
  .btn-nu-tab {
    border: 0px;
    // border-bottom: 2px solid transparent;
    border-bottom: 2px solid #e8e9ef;
    border-radius: 0px;
    padding-left: 0px;
    padding-right: 0px;
    color: #919193;
    font-weight: 400;
    margin-bottom: -2px;
    span {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 0px;
      padding-bottom: 0px;
      border-right: 1px solid #d0d1d6;
    }
    &.active {
      border: 0px;
      border-bottom: 2px solid #f5b841;
      color: #1c1f2c;
      font-weight: 600;
    }
    &:last-child {
      span {
        border: 0px;
      }
    }
  }
}
// ----------Numero Tab end ---------------- //

.contract-pdf-viewer{
  .e-pv-main-container {
    z-index: 2 !important;
  }
  .e-pv-viewer-container {
    background-color: #f7f7f7;
  }
}
.mdc-data-table__header-cell
{
  background-color: #F5F7FD !important;
  color: #626C8B !important;
}
.summary-content-size {
  font-weight: 500;
  h1 , h2 {
    font-size: 18px;
  }
  h3, h4 {
    font-size: 16px;
  }
  pre , pre code{
    overflow: hidden;
    text-wrap: wrap;
    font-size: 14px;
    font-family: "Inter", sans-serif !important;
    font-weight: 400;
    color: #1c1f2c;
    text-align: left;
  }
}

.mark-down-div{
  color: #1C1F2C;
  font-weight: 500;
  h1 , h2 {
    font-size: 18px;
  }
  h3, h4 {
    font-size: 16px;
  }
  pre , pre code{
    overflow: hidden;
    text-wrap: wrap;
    font-size: 14px;
    font-family: "Inter", sans-serif !important;
    font-weight: 400;
    color: #1C1F2C;
    text-align: left;
  }
  p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  br {
    display: block;
    content: "";
    margin-bottom: 1em; 
  }
  table{
    margin-bottom: 10px;
    margin-top: 10px;
    th {
      min-width: 150px;
    }
    td , th {
      border: 1px solid #d2dbee;
      padding: 6px;
    }
  }
}


.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #004080!important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #ffffff!important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-width: 1px!important;
}
.mdc-radio__outer-circle {
  border-width: 5px!important;
}

.mat-mdc-radio-button.mat-accent {
  --mat-mdc-radio-checked-ripple-color: transparent;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0!important;
}
// -------------DOT Flashing--------------------- //

.dot-flashing {
  margin-top: 4px;
  margin-left: 14px;
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #b7b7ba;
  color: #b7b7ba;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #b7b7ba;
  color: #b7b7ba;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #b7b7ba;
  color: #b7b7ba;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #b7b7ba;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

// -------------DOT Flashing End--------------------- //

/*numero-e-splitter */
/****************** e-splitter start ********************/
.e-splitter.e-splitter-horizontal {
  border: none !important;
  border-left: 3px solid #e0e0e0;
  border-right: 3px solid #e0e0e0;
}
 .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover,
 .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
  background:  #004080 !important;
}

/* default split bar resize handle color in hover and active state */
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-resize-handler,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
    color: #004080 !important;
}

/* split bar arrows */
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::after {
  background-color: #004080 !important;
}

/* split bar arrows - circular border */
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right {
  border-color: #004080 !important;
  color: #004080 !important;
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right {
  border-color: #004080 !important;
  color: #004080 !important;
}


.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler.e-hide-handler {
  visibility: visible !important;
}
/****************** e-splitter end ********************/

