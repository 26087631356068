/* Global styles can be added to this file, and also import other style files */
// @import "assets/syncfusion-style/material.css";
// @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
// @import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "assets/style/shared-component.scss";

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

html {
  height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  height: 100vh;
  box-sizing: border-box;
  margin: 0;
  // font-family: "Helvetica Now Display";
  font-family: "Inter", sans-serif !important;
  //background-color: $fin-background-color;
  // background-color: var(--fin-background-color);
  // background-color: #f5f5f5;
  background-color: #F1F5F9;
}

.mat-mdc-paginator {
  background-color: transparent;
}

#flow-manager {
  .am-flow-manager {
    left: 60px;
  }
}

#designer {
  .am-designer {
    left: 60px;
  }
}

.ff-ngc {
  // font-family: 'NanumGothicCoding';
  font-family: "Inter", sans-serif !important;
}

.cr {
  color: red;
  margin-left: 4px;
}

.bl-clr {
  color: #4578DB;
}

.diagram-container {
  height: 100vh;
  width: 100%;
}

.comments-overlay {
  background: #52b415;
  font-size: 12px;

  color: white;
  display: none;
}

.selected .comments-overlay,
.comments-overlay.with-comments {
  display: block;
}

.comments-overlay .edit {
  margin: 2px 0 0 0;
  padding-top: 2px;
}

.comments-overlay .edit textarea {
  border: none;
  margin: 0;
}

.comments-overlay .icon-comment:hover {
  color: #eee;
}

.comments-overlay .comment {
  border-top-color: white;
}

.comments-overlay .icon-comment {
  margin-right: 2px;
}

.comments-overlay .icon-delete {
  color: white;
}

.al-self-center {
  align-self: center;
}

.icon-comment:before {
  content: "\e800";
}
.icon-delete:before {
  content: "\e802";
}

.text-right {
  text-align: right;
}

.pl5 {
  padding-left: 5px;
}

.pr5 {
  padding-right: 5px;
}

.pl0 {
  padding-left: 0px;
}

.pr0 {
  padding-right: 0px;
}

// .btn-numero {
//   background-color: #1f8523;
//   color: #fff;
//   border-radius: 4px;
//   border: 1px solid #1f8523;
//   overflow: hidden;
//   position: relative;
//   z-index: 1;
//   transition: all 0.3s ease;
//   &:hover {
//     //background-color: #1F8523;
//     //box-shadow: 0 3px 5px 0 #1F85237F;
//     color: #1f8523;
//     box-shadow: 0 0 20px rgba(31, 133, 35, 0.2),
//       0 0 20px rgba(31, 133, 35, 0.2) inset;
//     border: 1px solid #1f8523;
//   }
// }
// .btn-numero:after {
//   content: "";
//   background: #fff;
//   width: 100%;
//   height: 0;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   transition: all 0.3s ease;
//   z-index: -1;
// }
// .btn-numero:hover:after {
//   height: 100%;
//   bottom: auto;
//   top: 0;
// }


.btn-numero {
  // background-color: #4597F7;
  // border-color: #4597F7;
  background-color: #004080;
  border-color: #004080;
  color: #fff;
  font-size: 12px;
  // font-weight: 500;
  // border-radius: 4px;
  // letter-spacing: 0.4px;
  // transition: 0.4s;
  // text-transform: uppercase;
  // padding: 6px 12px 4px;
  &:hover {
      background-color: #0059B3;
      border-color: #0059B3;
      color: #fff;
  }
  &:disabled {
    background-color: #DBDCDE;
    border-color: #DBDCDE;
    font-weight: 600;
    color: #1C1F2C;
    pointer-events: none;
  }
}

.btn-numero-outline {
  background-color: transparent;
  border-color:  #004080;
  color:  #004080;
  font-size: 12px;
  &:hover {
    background-color: transparent;
    border-color:  #004080;
    color:  #004080;
  }
  &:disabled {
    background-color: #DBDCDE;
    border-color: #DBDCDE;
    font-weight: 600;
    color: #1C1F2C;
  }
}

.btn-submit {
  background-color: #1F8523;
  border-color: #1F8523;
  color: #fff;
  font-size: 12px;
  // font-weight: 500;
  // border-radius: 4px;
  // letter-spacing: 0.4px;
  // transition: 0.4s;
  // text-transform: uppercase;
  // padding: 6px 12px 4px;
  &:hover {
      background-color: #1F8523;
      border-color: #1F8523;
      color: #fff;
  }
  &:disabled {
    background-color: #DBDCDE;
    border-color: #DBDCDE;
    font-weight: 600;
    color: #1C1F2C;
  }
}

.btn-danger {
  background-color: #1F8523;
  border-color: #1F8523;
  color: #fff;
  // font-size: 12px;
  // font-weight: 500;
  // border-radius: 4px;
  // letter-spacing: 0.4px;
  // transition: 0.4s;
  // text-transform: uppercase;
  // padding: 6px 12px 4px;
  &:hover {
      background-color: #1F8523;
      border-color: #1F8523;
      color: #fff;
  }
  &:disabled {
    background-color: #DBDCDE;
    border-color: #DBDCDE;
    font-weight: 600;
    color: #1C1F2C;
  }
}

.btn-danger {
  border-color: #DA2949;
  background-color: #DA2949;
  color: #fff;
  // border-radius: 4px;
  // letter-spacing: 0.4px;
  // transition: 0.4s;
  // text-transform: uppercase;
  // padding: 6px 12px 4px;
  &:hover {
    border-color: #9f1931;
    background-color: #9f1931;
  }
}

.btn-cancel {
  border-color: #555761;
  background-color: #ffffff;
  color: #4D4D4D;
  &:hover {
    border-color: #555761;
    background-color: transparent;
  }
}

.btn-secondary, .btn-submit , .btn-danger , .btn-numero, .btn-cancel, .btn-numero-outline {
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.4px;
  transition: 0.4s;
  text-transform: uppercase;
  padding: 6px 12px 4px;
  font-size: 14px;
}

.nu-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  width: fit-content;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  border: 0px;
  color: #ffffff;
  &-green {
    background-color: #6d9f6f;
  }

  &-blue {
    background-color: #4169e1;
  }

  &-red {
    background: #9e3333;
  }
  &-grey{
    background: #555761;
  }
  &-purple {
    background-color: #6c6ace;
  }
}

.label-beta {
  background-color: #F5B841;
  border-radius: 4px;
  padding: 4px 8px;
  color: #1c1f2c;
  border: 0px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
}


/* css for toggle switch - Starts */

.version.dropdown-menu {
  min-width: 75px;
  left: 150px;
  .dropdown-item.active {
    background-color: #353b45 !important;
    color: #f5b841 !important;
  }
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-color: #c8c8d6;
  border: #e8e8ea solid 1px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  left: -2.25rem;
  pointer-events: all;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1f8523;
  background-color: #1f8523;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
  cursor: pointer;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  // background-color: #adb5bd;
  background-color: #fff;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

@mixin switch($res: "sm") {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == "md" {
    $index: 2rem;
    $mainVal: 1.5rem;
  } @else if $res == "lg" {
    $index: 3rem;
    $mainVal: 2rem;
  } @else if $res == "xl" {
    $index: 4rem;
    $mainVal: 2.5rem;
  }

  .custom-control-label::before {
    height: $mainVal;
    width: calc(#{$index} + 0.75rem);
    border-radius: $mainVal * 2;
  }

  .custom-control-label::after {
    width: calc(#{$mainVal} - 4px);
    height: calc(#{$mainVal} - 4px);
    border-radius: calc(#{$index} - (#{$mainVal} / 2));
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(#{$mainVal} - 0.25rem));
  }
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl
.custom-switch.custom-switch-sm {
  @include switch();
}

.custom-switch.custom-switch-md {
  @include switch("md");
}

.custom-switch.custom-switch-lg {
  @include switch("lg");
}

.custom-switch.custom-switch-xl {
  @include switch("xl");
}

/* css for toggle switch - Ends */

.div-head {
  p {
    font-size: 22px;
    font-weight: 600;
    color: #4d4d4d;
  }
}

.div-numero-container {
  padding: 0px 30px 20px 130px;
  width: 100%;
}

.div-top-menu {
  // border-bottom: 1px solid #ddd;
  margin-top: 24px;
  .btn-menu {
    background-color: #fff;
    // background-color: #f5f5f5;
    border: 0px;
    // border-bottom: 2px solid #fff;
    border-bottom: 2px solid #f5f5f5;
    color: #655e7a;
    font-size: 14px;
    padding: 8px 0px;
    border-radius: 0px;
    &.active {
      background-color: #EEEFF4;
      border-bottom: 2px solid #1C1F2C;
      color: #1C1F2C;
      &:hover {
        border-bottom: 2px solid #1C1F2C;
        color: #1C1F2C;
      }
    }
    &:hover {
      border-bottom: 2px solid #c8ccd2;
      color: #192435;
    }
    p {
      margin-bottom: 0px;
      padding: 0px 12px;
      &.br {
        border-right: 1px solid #D0D1D6;
      }
    }
    &:last-child {
      p.br {
        border-right:0px;
      }
    }
  }
}

.btn-icon {
  background-color: #fff;
  border: 1px solid #30365e;
  color: #30365e;
  border-radius: 4px;
}

.bpmn-icon-task.red {
  color: #cc0000 !important;
}

.bpmn-icon-task.yellow {
  color: #ffc800 !important;
}

.bpmn-icon-task.green {
  color: #52b415 !important;
}

.div-main-content {
  // padding-left: 250px;
  // padding-left: 95px;
  // padding-left: 110px;
}

// pointer
.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed!important;
}

.pe-none {
  pointer-events: none;
}

.v-al-cent {
  display: flex;
  align-items: center;
}
// Custom Toast Message - Starts

// word-wrap
.word-wrap {
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}

#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: relative;
  font-size: 24px;
  line-height: 18px;
  float: left;
  margin-left: -1em;
  color: #fff;
  padding-right: 0.5em;
  margin-right: 0.5em;
}
#toast-container .toast {
  background-color: #1b75bc !important;
}
#toast-container > .fa-check-circle,
.toast {
  background-color: #c7e3cc !important;
}
#toast-container > .fa-exclamation-triangle,
.toast {
  background-color: #fee2d5 !important;
}
#toast-container > .fa-exclamation-circle,
.toast {
  background-color: #FEE2D5 !important;
}
#toast-container > .fa-check-circle {
  color: #328936;
}
#toast-container > .fa-exclamation-triangle {
  color: #eb2e2e;
}
#toast-container > .fa-exclamation-circle {
  color: #FF6F2C;
}
#toast-container .toast-message {
  color: #4d4d4d;
  font-family: "Inter", sans-serif !important;
  // font-size: 15px;
  font-size: 14px;
}
#toast-container .fa-check-circle:before,
#toast-container .fa-exclamation-triangle:before,
#toast-container .fa-exclamation-circle:before {
  position: absolute;
  left: 15px;
  font-size: 18px;
}

.toast-top-center {
  text-align: center;
  top: 20px !important;
}

#toast-container .toast-title {
  display: none;
}

.toast-container .ngx-toastr {
  text-align: left;
  width: 375px !important;
  // padding: 15px 15px 11px 45px !important;
  padding: 20px 15px 16px 45px !important;
  box-shadow: none !important;
}

#toast-container .toast-close-button {
  color: #4d4d4d;
  font-weight: 200;
  text-shadow: 0 1px 0 #4d4d4d;
  top: -3px;
}

// Custom Toast Message - Ends

.avatar-content {
  font-size: 15px !important;
  border-radius: 4px !important;
  // border: 1px solid #DADADA;
  border: 0px solid #DADADA;
}

.div-in-avatar {
  .avatar-content {
      border-radius: 20px !important;
  }
}

.div-avatar {
  height: 25px;
  width: 25px;
  border-radius: 4px;
  color: #fff;
  background-color: #3498db;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

//Custom table - start

.tbl-numero {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-collapse: initial !important;
  border-spacing: 0;
  border-radius: 8px;
  margin-bottom: 30px;
}

.tbl-numero thead tr th {
  color: #848484;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  border-top: 0px;
}

.tbl-numero tbody tr td {
  color: #4d4d4d;
}

.tbl-numero thead tr th,
.tbl-numero tbody tr td {
  padding: 8px 15px;
}

.tbl-numero th:first-child {
  border-radius: 8px 0 0 0;
}

.tbl-numero tbody tr td span {
  background-color: #f2f3f9;
  padding: 4px 6px;
  border-radius: 4px;
  margin-right: 2px;
}
.tbl-numero tbody tr td span {
  &.span-more,
  &.span-less {
    font-size: 12px;
    color: #0645ad;
    background-color: transparent;
    padding: 0px;
  }
}

.tbl-numero tbody tr td .container-checkbox input:checked ~ .checkmark {
  background-color: #7bbcac;
}

.tbl-numero tbody tr td a {
  color: #00138b;
}

.tbl-numero tbody tr td a i {
  margin-left: 5px;
  font-size: 12px;
}

.tbl-numero tbody tr:last-child td {
  border-bottom: 0px;
}

.div-custom-table {
  width: 100%;
}

/* media query for screen width 1920 */
@media screen and (min-width: 1920px) {
  .div-custom-table {
    width: 100%;
  }
}
/* media query for screen width 1440 */
@media only screen and (max-width: 1919px) and (min-width: 1560px) {
  .div-custom-table {
    width: 95%;
  }
}

// Custom table - end

// Custom modal - Start

.modal.left .modal-dialog {
  position: fixed;
  // right: 0;
  right: 30px;
  margin: auto;
  // width: 320px;
  width: 375px;
  height: 74vh;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  // margin-top: 16vh;
  //margin-top: 26vh;
  bottom: 0px;
}

.modal.left .modal-content {
  //height: 100%;
  overflow-y: auto;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 74vh;
}

.modal-dialog {
  .modal-content {
    background-color: #FBFBFB;
    border: 0px;
  }
}

.modal.left .modal-dialog .modal-content .modal-body {
  padding: 20px;
}

.modal.right .modal-body {
  padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

// Custom modal - End

.col-md-numero {
  flex: 0 0 auto;
  width: 20%;
  padding-bottom: 15px !important;
}

.div-search-mat {
  background-color: #ffffff;
}
.div-mat-card {
  // background: #fdfdfd;
  border: 1px solid #e3e2e2;
  border-radius: 4px;
  // overflow: hidden;
  .div-search-mat {
    // max-width:320px;
    max-width: 280px;
    // border: 1px solid #cccccc;
    border: 1px solid #DDDDDF;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 20px;
    // height: 28px;
    height: 32px;
    .input-group-prepend {
      // padding: 10px 10px 10px 15px;
      // padding: 5px 10px 5px 10px;
      // padding: 1px 10px 0px 10px;
      padding: 4px 10px 0px 10px;
      i {
        // color: #cccccc;
        color: #8d8f95;
      }
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled),
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
      .mat-mdc-form-field-infix {
      // padding-top: 6px;
      // padding-bottom: 4px;
      // padding-top: 1px;
      padding-top: 3px;
      padding-bottom: 0px;
      .mat-mdc-form-field-input-control {
        font-size: 14px;
        color: #1C1F2C;
      }
    }
    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: #8d8f95;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mdc-text-field--focused:not(.mdc-text-field--disabled)
      .mdc-floating-label {
      display: none;
    }
    .mat-mdc-form-field-infix {
      min-height: unset;
      width: 210px;
    }
    .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex
      .mat-mdc-floating-label {
      // top: 17px;
      // top: 13px;
      top: 15px;
      font-size: 14px;
    }
    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom-width: 0px;
    }

    .mdc-text-field {
      padding-left: 0px;
      padding-right: 0px;
    }

    .mdc-floating-label--float-above {
      display:none;
    }

    // .mat-form-field {
    //   width:80%;
    // }
    // .mat-form-field-wrapper {
    //   padding-bottom: 0px;
    // }
    // .mat-form-field-underline {
    //   display:none;
    // }
    // .mat-form-field-infix {
    //   border-top: 2px solid transparent;
    // }
    // .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    //   transform: translateY(-1.00em) scale(.75) perspective(100px) translateZ(.001px);
    //   display:none;
    // }
  }
  .mat-elevation-z8 {
    box-shadow: 0 0px 0px 0px #0003, 0 0px 0px 0px #00000024,
      0 0px 0px 0px #0000001f;
      font-family: "Inter", sans-serif !important;

    .mat-mdc-table {
      background: #fdfdfd;
      border-bottom: 1px solid #ddd;
      // min-height: 260px;
      .mdc-data-table__header-cell {
        background-color: #f6f9fc;
        color: #4d4d4d;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        font-family: "Inter", sans-serif !important;
      }
      .mdc-data-table__cell {
        font-family: "Inter", sans-serif !important;
      }
    }
    .mat-sort-header-container{
      align-items: baseline;
    }
    .mat-mdc-paginator {
      font-family: "Inter", sans-serif !important;
    }
    .mat-paginator-container {
      padding-right: 20px;
    }
  }
}
.mat-mdc-table {
  width: 100%;
  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }
  .mat-cell {
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .mat-mdc-header-row {
    &:hover {
      .resize-holder {
        display: block!important;
      }
    }
  }
  .mat-mdc-header-cell {
    position: relative;
    &:not(:last-child) {
      .resize-holder {
        cursor: col-resize;
        width: 20px;
        height: 70%;
        position: absolute;
        right: -10px;
        /* margin-right: 10px; */
        top: 8px;
        /* padding-top: 27px; */
        border-left: 1.5px solid #bdb3b3;
        z-index: 0;
        display: none;
      }
    }
  }
  .mat-cell,
  .mat-header-cell {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    &:not(:nth-child(1)) {
      padding: 0 10px;
    }
  }
}

.workbench-ap-open .mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: 1px solid #D2DBEE;
}

.p0 {
  padding-left: 0px;
  padding-right: 0px;
}

.m0 {
  margin-left: 0px;
  margin-right: 0px;
}

/* Custom Scrollbar - Starts */

/* Only works for Firefox */
// * {
//   scrollbar-width: thin; /* "auto" or "thin" */
//   scrollbar-color: #ddd #333; /* scroll thumb and track */
// }

/* Only works for Firefox */

/* Let's get this party started */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #ECECEC;
  border: 0px!important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /* background: #191c24;  */
  // background: #ddd;
  background: #c5c5c5;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  // background: #ddd;
  background: #c5c5c5;
}

/* Custom Scrollbar - Ends */

/* Responsive css - Starts */

/* media query for screen width 2560 */
@media only screen and (max-width: 3839px) and (min-width: 2560px) {
}

/* media query for screen width 1920 */
@media only screen and (max-width: 2559px) and (min-width: 1920px) {
}
/* media query for screen width 1440 */
@media only screen and (max-width: 1460px) and (min-width: 1440px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 25%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 1366 */
@media only screen and (max-width: 1439px) and (min-width: 1366px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 25%;
    padding-bottom: 15px !important;
  }
  .div-mat-card .div-search-mat .mat-mdc-form-field-infix {
    min-height: unset;
    width: 200px;
  }
}
/* media query for screen width 1280 */
@media only screen and (max-width: 1365px) and (min-width: 1280px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 25%;
    padding-bottom: 15px !important;
  }
  .div-mat-card .div-search-mat .mat-mdc-form-field-infix {
    min-height: unset;
    width: 180px;
  }
}
/* media query for screen width 1024 */
@media only screen and (max-width: 1279px) and (min-width: 1024px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 33.33%;
    padding-bottom: 15px !important;
  }
  .div-mat-card .div-search-mat .mat-mdc-form-field-infix {
    min-height: unset;
    width: 115px;
  }
}
/* media query for screen width 800 */
@media only screen and (max-width: 1023px) and (min-width: 800px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 33.33%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 768 */
@media only screen and (max-width: 799px) and (min-width: 768px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 50%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 600 */
@media only screen and (max-width: 767px) and (min-width: 600px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 50%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom: 15px !important;
  }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
  .col-md-numero {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom: 15px !important;
  }
}

/* Responsive css - Ends */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.dashboard-container {
  padding: 0px 0px 20px 79px;
  width: 100%;
  height: 100vh;
  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
}

.workbench-ap-open,
.workbench-ap-rejected,
.workbench-ap-approved,
.workbench-ap-paid {
  overflow: auto;
  .mat-mdc-paginator-container {
    justify-content: right;
  }
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  border-color: #8D8F95 !important;
  border-width: 1px;
  border-radius: 4px;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: #004080 !important;
  background-color: #004080 !important;
  height: 16PX!important;
  width: 16PX!important;
  border-radius: 4px;
}

.mdc-checkbox__checkmark {
  top: 1;
  height: 10PX;
}


.mat-mdc-checkbox.mat-accent
  .mdc-checkbox--selected
  ~ .mat-mdc-checkbox-ripple
  .mat-ripple-element {
  background-color: #1f85231a;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  // background: #1f8523;
  background: #004080;
}


.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 7px 16px 0 16px !important;
  border-color: #D2DBEE;
}

/* Custom Check box - Starts */

/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  color: #1C1F2C;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disable-checkbox {
  pointer-events: none;
  .container-checkbox{
    color: #80808033;
    .checkmark{
      background-color: #b1a3a324;
    }
  }
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #9d9b9b;
  border-radius: 4px;
}

.tbl-alert-card .container-checkbox .checkmark {
  top: -3px;
}


.numero-check{
  padding-left: 0px;
  margin-right: 0px;
  &.hide-tick {
    .container-checkbox{
      padding-left: 5px;
    }
  }
}
/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #7bbcac;
  border-color: #7bbcac;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 6px;
  // top: 3px;
  top: 1px;
  width: 5px;
  // height: 10px;
  height: 11px;
  border: solid white;
  // border-width: 0 3px 3px 0;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdc-checkbox__checkmark {
  top: 1;
  height: 10PX;
}


/* Custom Check box - Ends */

.accordion-button:not(.collapsed)::after {
  background-image:  url('../src/assets/image/temp/arrow-down.svg');
}



.sel-cust {
  margin-right: 15px;
  width: 195px;
  // padding: 1px 2.25rem 4px 0.75rem;
    // line-height: 24px;
    padding: 3px 2.25rem 6px 0.75rem;
    line-height: 26px;
  font-size: 14px;
  // height:28px;
  height: 32px;
  // background-image: url(/assets/image/dropdown.svg) !important;
  border-color: #DDDDDF;
  color: #1C1F2C;

}

.div-export-pdf-excel {
  .div-export-btn {
    // background-color: #4597F7;
    // border-color: #4597F7;
    border: 1px solid #8D8F95;
    background-color: transparent;
    color: #fff;
    // padding: 1px 14px;
    padding: 3px 14px;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: 0.4s;
    &::after {
      display:none;
    }
    i {
      color: #8D8F95;
      transition: 0.4s;
    }
    &:hover {
      border: 1px solid #1C1F2C;
      i {
        color: #1C1F2C;
      }
    }
    &:disabled {
      cursor:not-allowed;
      background-color: #EFF0F0;
      &:hover {
          background-color: #EFF0F0;
          border-color: #8D8F95;
          i {
              color: #8D8F95;
          }
      }
    }
  }
}

.div-export-dropdown {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border-color: #fff;
  min-width: 120px;
  padding: 15px 0px;
  margin-top: 8px;
  button {
    width: 100%;
    background-color: #fff;
    border: 0px;
    text-align: left;
    padding: 6px 16px;
    font-size: 14px;
    &:hover {
      background-color: #E6E7EC;
    }
    img {
      margin-top: -5px;
      width:18px;
    }
  }
}

.e-control, .e-css {
  font-family: "Inter", sans-serif !important;
}

.btn-ru {
  border: 1px solid #DEDFE0;
  background-color: #EFF0F0;
  color: #777980;
  // padding: 1px 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.4s;
  margin-top: 20px;
  margin-bottom: 18px;
  // font-size: 10px;
  // font-weight: 600;
  margin-left: 12px;
  height:31px;
  width:31px;
  i {
      font-size: unset;
  }
  &:hover {
    background-color: #CACBCE;
      border-color: #CACBCE;
      color: #777980;
  }
  &:disabled {
    cursor:not-allowed;
    &:hover {
        background-color: transparent;
        border-color: #8D8F95;
        i {
            color: #8D8F95;
        }
    }
  }
}

.btn-ref-exp {
  border-radius: 4px;
  border: 1px solid #DEDFE0;
  background: #FFF;
  cursor: pointer;
  transition: 0.4s;
  margin-top: 20px;
  margin-bottom: 18px;
  margin-left: 12px;
  height:31px;
  font-size: 14px;
  color: #777980;
  i {
    color: #777980;
  }
}

.div-details-content {
  .div-dropdown-btn {
    font-weight: 500;
    border-radius: 4px !important;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  }
}

.div-parent-num-card {
  .div-card-title {
    p {
      font-size: 14px !important;
      text-transform: uppercase;
    }
  }
}

.text-capitialize{
  text-transform: capitalize;
}

.text-trans-none{
  text-transform: none;
}

.text-upper{
  text-transform: uppercase;
}

.text-lower{
  text-transform: lowercase;
}

.p-mb-none {
  p{
      margin-bottom: 0px !important;
  }
}
.toast-message {
  line-height: 1.5;
}
